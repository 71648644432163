export const dummyImage =
  "https://assets.website-files.com/643d8b145b9de160a97cf566/644146cc0b106fd053b1b3fd_Rectangle%2041179.jpg";
export const dummyImage2 =
  "https://assets.website-files.com/643d8b145b9de160a97cf566/64414cd179cae64ec7f55948_Group%20427319265.svg";
export const image1 = "https://assets.website-files.com/643d8b145b9de160a97cf566/6442ac2b40e4d57243e355ea_Rectangle%205.jpg"

export const aboutSectionImage1 = "https://assets.website-files.com/643d8b145b9de160a97cf566/64420e1eea57b57367586b32_Rectangle%2041186.jpg"

export const aboutSectionImage2 = "https://assets.website-files.com/643d8b145b9de160a97cf566/64420e1e621d9f5fb6e41cd5_Rectangle%2041189.jpg"

export const aboutSectionImage3 = "https://assets.website-files.com/643d8b145b9de160a97cf566/64420e1ebded42e6dcc69ef5_Rectangle%2041185.jpg"